export const APPLICATION_STATE_WARNINGS = 'applicationStateWarnings';
export const DEV_TOOLS_PERFORMANCE_STORAGE_KEY = 'devtools_performance';
export const DUPLICATED_ID = 'duplicatedId';
export const DUPLICATED_ID_MARK_STYLE_ID = 'duplicated-id-mark';
export const EMPTY_NODE = 'emptyNode';
export const EMPTY_NODE_MARK_STYLE_ID = 'empty-node-mark';

export type PerformanceAnalyzeItem =
  | 'applicationStateWarnings'
  | 'duplicatedId'
  | 'emptyNode';
