import type { DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react';

export const generateLoadingByElementId = (
  elementId: string,
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> = {},
): JSX.Element | null => {
  try {
    const placeholder = document.getElementById(elementId);

    return (
      <div
        {...props}
        suppressHydrationWarning
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: placeholder?.innerHTML || '' }}
      />
    );
  } catch {
    return null;
  }
};

export const generateLoadingByHydrateId = (
  elementId: string,
  fallback?: ReactElement,
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> = {},
): JSX.Element | null => {
  try {
    const placeholders = Array.from(
      document.querySelectorAll(`[data-hydrate-id='${elementId}'`),
    );

    if (placeholders.length === 0) return fallback || null;

    // order the placeholders by real position in the page and take the first one
    // technically it should be the closest to where we are in case we are not in
    // 'visible' mode but that should be even more of an edge case
    const placeholder = placeholders.sort(
      (elem1, elem2) =>
        elem1.getBoundingClientRect().top - elem2.getBoundingClientRect().top,
    )[0];

    if (!placeholder?.childElementCount) return fallback || null;

    return (
      <div
        data-hydrate-id={elementId}
        {...props}
        suppressHydrationWarning
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: placeholder?.innerHTML || '' }}
      />
    );
  } catch {
    return null;
  }
};
