import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';
import type React from 'react';

import logger from '@/domains/core/observability/logger';
import { getErrorCode } from '@/domains/retention/Homepage/b2c/error/getErrorCode';
import { useMsfHomepageError } from '@/domains/retention/Homepage/b2c/hooks/useMsfHomepageError';
import type { PropChildren } from '@/domains/retention/Homepage/b2c/interfaces/prop.interface';

const Error404 = dynamic<React.PropsWithChildren<unknown>>(() =>
  import('@/domains/core/page/components/Error404/Error404').then(
    (mod) => mod.Error404,
  ),
);
const Error500 = dynamic<React.PropsWithChildren<unknown>>(() =>
  import('@/domains/core/page/components/Error50x/Error500').then(
    (mod) => mod.Error500,
  ),
);
const Error504 = dynamic<React.PropsWithChildren<unknown>>(() =>
  import('@/domains/core/page/components/Error50x/Error504').then(
    (mod) => mod.Error504,
  ),
);

export const PageStatusManager: FunctionComponent<PropChildren> = ({
  children,
}) => {
  const error = useMsfHomepageError();

  if (!error) return <>{children}</>;

  switch (getErrorCode(error)) {
    case 404:
      return <Error404 />;
    case 504:
      logger.error(new Error(error));
      return <Error504 />;
    case 500:
    default:
      logger.error(new Error(error));
      return <Error500 />;
  }
};

PageStatusManager.displayName = 'PageStatusManager';
