import { useContext } from 'react';

import { useUserTiming } from '@/core/observability/utils/useUserTiming';
import { Link } from '@/core/routing/components/Link';
import { Carousel } from '@/core/tamagoshi/Carousel/Carousel';
import { getSeoData } from '@/domains/retention/Homepage/b2c/modules/Seo/utils/getSeoData';
import { SectionTitle } from '@/retention/Homepage/b2c/components/SectionTitle/SectionTitle';
import TrackingContext, {
  type TrackingContextValue,
} from '@/retention/Homepage/b2c/modules/Tracking/TrackingContext';

import { ariaLabelNext, ariaLabelPrevious, title } from './translations';

import styles from './seo.module.scss';

export const Seo = () => {
  useUserTiming('homepageB2C/seo');
  const tracking = useContext<TrackingContextValue>(TrackingContext);

  const seo = getSeoData() || [];
  if (!seo || seo.length === 0) {
    return null;
  }

  return (
    <section className={styles.root}>
      <Carousel
        ariaLabelLeft={ariaLabelPrevious}
        ariaLabelRight={ariaLabelNext}
        dataTestId="seo"
        title={<SectionTitle title={title} />}
        titleClassName={styles.carouselTitle}
        className={styles.carousel}
        controlsClassName={styles.carouselControls}
        sliderClassName={styles.carouselSlider}
      >
        {seo.map((seoFamily, familyIndex) => (
          <div
            key={`${seoFamily.categoryPath}-${seoFamily.familyId}`}
            className={styles.fullFamily}
          >
            <Link
              href={seoFamily.categoryPath}
              className={styles.family}
              data-testid="seo-family-category"
              onClick={() => tracking?.onClickSeoBlock(seoFamily, familyIndex)}
            >
              {seoFamily.categoryName}
            </Link>
            <ul className={styles.ul}>
              {seoFamily.items.map((seoItem, index) => (
                <li
                  key={`${seoFamily.categoryPath}-${seoFamily.familyId}-${seoItem.title}`}
                  className={styles.item}
                >
                  <Link
                    href={seoItem.url}
                    className={styles.url}
                    data-testid="seo-family-subcategory"
                    onClick={() => {
                      tracking?.onClickSeoBlock(
                        seoFamily,
                        familyIndex,
                        seoItem,
                        index,
                      );
                    }}
                  >
                    {seoItem.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Carousel>
    </section>
  );
};

Seo.displayName = 'Seo';
