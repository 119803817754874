import { HttpStatusCode } from '@/domains/core/httpClient/HttpStatusCode';

export enum ErrorCode {
  TIMEOUT_ERROR = 'TIMEOUT_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  NETWORK_ERROR = 'NETWORK_ERROR',
}

export const getErrorCode = (error?: string): number | undefined => {
  if (!error) {
    return;
  }

  const isMsfHomepageB2cDeploying = error.includes('name resolution failed');
  const isRouteUnknown = error.includes(ErrorCode.NOT_FOUND);
  const isClientTimeout = error.includes(ErrorCode.TIMEOUT_ERROR);
  const isNetworkError = error.includes(ErrorCode.NETWORK_ERROR);

  if (
    isRouteUnknown ||
    isMsfHomepageB2cDeploying ||
    isClientTimeout ||
    isNetworkError
  ) {
    return HttpStatusCode.GATEWAY_TIMEOUT_ERROR;
  }

  return HttpStatusCode.INTERNAL_ERROR;
};
