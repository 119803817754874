import { createContext } from 'react';

import type {
  BrandModel,
  SeoItem,
  SeoModel,
} from '@/domains/retention/Homepage/b2c/interfaces/state.model';

export interface TrackingContextValue {
  onClickBrand: (brand: BrandModel) => void;
  onClickSeoBlock: (
    seoFamily: SeoModel,
    familyIndex: number,
    seoItem?: SeoItem,
    index?: number,
  ) => void;
}

export const defaultValue = {
  onClickBrand: () => {},
  onClickSeoBlock: () => {},
};

const TrackingContext = createContext<TrackingContextValue>(defaultValue);

export const TrackingProvider = TrackingContext.Provider;
export default TrackingContext;
