import { useCallback, type FunctionComponent, type KeyboardEvent, type MouseEvent } from 'react';
import { translate, translateFormatter } from '@/domains/core/localization/translate';
const description = translateFormatter("Produit mis en avant dans le cadre d'un service optionnel payant souscrit par le Marchand. <a>En savoir plus</a>", undefined, undefined, "fr");
export const {
  link
} = translate({
  "link": "https://www.manomano.fr/infos-consommateurs#sponsor"
});
export const SponsoredDescription: FunctionComponent = () => {
  const redirectLink = useCallback((event: KeyboardEvent<HTMLSpanElement> | MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    if (link) {
      window.location.href = link;
    }
    return null;
  }, []);
  return <>
      {description.format({
      a: (chunks: string) => <span key={chunks} role="link" tabIndex={0} onClick={redirectLink} onKeyPress={redirectLink}>
            {chunks}
          </span>
    })}
    </>;
};
SponsoredDescription.displayName = 'SponsoredDescription';