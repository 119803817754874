import { SvgOptimizer } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer';

import { useDynamicReducer } from '@/domains/core/applicationState/hooks/useDynamicReducer';
import { HttpStatusCode } from '@/domains/core/httpClient/HttpStatusCode';
import { traceServerSideProps } from '@/domains/core/observability/tracing/traceServerSideProps';
import type {
  InferPage,
  PageProps,
} from '@/domains/core/page/interfaces/PageProps';
import { updateResponseAndProps } from '@/domains/core/page/utils/updateResponseAndProps';
import { getPageProps } from '@/domains/core/shell/page/getPageProps';
import { getErrorCode } from '@/domains/retention/Homepage/b2c/error/getErrorCode';
import { homepageEffects } from '@/domains/retention/Homepage/b2c/Homepage.saga';
import HomepageB2C from '@/domains/retention/Homepage/b2c/HomepageB2C';
import { pageName } from '@/domains/retention/Homepage/b2c/translations';
import { HOMEPAGE_PAGE_TYPE } from '@/domains/retention/Homepage/constants';
import { GET_HOMEPAGE } from '@/domains/retention/Homepage/Homepage.actions';
import homepageReducer from '@/domains/retention/Homepage/Homepage.reducer';
import type { SpartacuxRetentionState } from '@/domains/retention/retention.model';
import { initPartnerAdTheme } from '@/sellerAdsManagement/PartnerAdTheme/PartnerAdTheme.actions';
import { partnerAdThemeReducer } from '@/sellerAdsManagement/PartnerAdTheme/partnerAdTheme.reducer';

const Homepage: InferPage<typeof getServerSideProps> = () => {
  useDynamicReducer('retention.homepage', homepageReducer);

  return (
    <SvgOptimizer>
      <HomepageB2C />
    </SvgOptimizer>
  );
};

export const getServerSideProps = traceServerSideProps(async (context) => {
  const reducers = new Map();
  reducers.set('retention.homepage', homepageReducer);
  reducers.set('sellerAdsManagement.partnerAdTheme', partnerAdThemeReducer);

  const pageProps = await getPageProps(context, {
    sagas: homepageEffects,
    reducers,
    actions: [
      {
        type: GET_HOMEPAGE,
      },
      initPartnerAdTheme(),
    ],
  });

  const CACHE_MAX_AGE_MINUTES = 60;
  const CACHE_MAX_AGE_IN_SECONDS = CACHE_MAX_AGE_MINUTES * 60;
  return updateResponseAndProps<PageProps>(
    context,
    {
      ...pageProps,
      pageType: HOMEPAGE_PAGE_TYPE,
      pageName,
      statusCode:
        getErrorCode(
          (pageProps.initialReduxState as SpartacuxRetentionState).retention
            ?.homepage?.error,
        ) || HttpStatusCode.OK,
    },
    {
      cacheOptions: { sMaxAge: CACHE_MAX_AGE_IN_SECONDS },
    },
  );
}, HOMEPAGE_PAGE_TYPE);

export default Homepage;
Homepage.displayName = 'Homepage';
