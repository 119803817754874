import { createRef, useContext } from 'react';

import { useUserTiming } from '@/core/observability/utils/useUserTiming';
import { SectionTitle } from '@/retention/Homepage/b2c/components/SectionTitle/SectionTitle';
import { useBrands } from '@/retention/Homepage/b2c/hooks/useBrands';
import TrackingContext, {
  type TrackingContextValue,
} from '@/retention/Homepage/b2c/modules/Tracking/TrackingContext';
import { BrandsSlider } from '@/retention/Homepage/components/BrandsSlider/BrandsSlider';
import { BrandsSliderTrackingProvider } from '@/retention/Homepage/components/BrandsSlider/BrandsSliderTrackingContext';

import { title } from './translations';

import styles from './Brands.module.scss';

export const Brands = () => {
  useUserTiming('homepageB2C/brands');
  const tracking = useContext<TrackingContextValue>(TrackingContext);
  const root = createRef<HTMLDivElement>();
  const brands = useBrands();
  return (
    <section ref={root} className={styles.root}>
      {!!brands?.length && (
        <BrandsSliderTrackingProvider value={tracking}>
          <BrandsSlider
            title={
              <SectionTitle title={title} className={styles.carouselTitle} />
            }
            titleClassName={styles.carouselTitle}
            brands={brands}
          />
        </BrandsSliderTrackingProvider>
      )}
    </section>
  );
};

Brands.displayName = 'Brands';
