import { useSelector } from 'react-redux';

import { type ProductModel } from '@/retention/Homepage/b2c/interfaces/state.model';
import { type SpartacuxRetentionState } from '@/retention/retention.model';

export const promotedProductsSelector = (
  state: SpartacuxRetentionState,
): ProductModel[] | undefined =>
  state.retention?.homepage?.data?.promotedProducts;

export const usePromotedProducts = () => useSelector(promotedProductsSelector);
