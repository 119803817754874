import type {
  SeoItem,
  SeoModel,
} from '@/domains/retention/Homepage/b2c/interfaces/state.model';

export const GTM_CLICK_ON_SEO_BLOCK = (
  seoFamily: SeoModel,
  familyIndex: number,
  seoItem?: SeoItem,
  index?: number,
) => ({
  event: 'interaction_click on family block item',
  e_category: 'navigation',
  event_trigger: 'homepage b2c',
  e_action: ' Click on family block',
  e_label: seoItem ? seoItem.title : seoFamily.categoryName,
  amp_event_name: 'Click on family block item',
  redirection_link: seoItem ? seoItem.url : seoFamily.categoryPath,
  category_name: seoFamily.categoryName,
  category_id: seoFamily.familyId,
  category_position: familyIndex + 1,
  subcategory_name: seoItem?.title,
  subcategory_id: undefined, // Return the id of the subcategory clicked => not available // TODO HP-505
  subcategory_position: index ? index + 1 : undefined, // Return the position of the subcategory clicked. Must start by 1
});
