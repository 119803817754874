import { useEffect, useState } from 'react';
import {
  useInView,
  type IntersectionOptions,
  type InViewHookResponse,
} from 'react-intersection-observer';

import { usePageChange } from '@/domains/core/routing/hooks/usePageChange';

const DISPLAY = 2000;
const THRESHOLD_MINIMUM_DISPLAY = 0.9;

type NodeRef = InViewHookResponse['ref'];

interface Options extends IntersectionOptions {
  /**
   * The track viewed element is reset when the pathname changes. With this
   * option, the track viewed element is also reset when the search params.
   */
  resetViewedWithSearchParams?: boolean;
}

/**
 * Custom hook that tracks the visibility of an element using the Intersection
 * Observer API.
 * It returns a ref to attach to the element and a boolean indicating whether
 * the element is currently in view.
 * Optionally, a callback function can be provided to be executed when the
 * element becomes visible.
 * The track viewed element is reset when the pathname changes. So, if the
 * element is in view and the user navigates to another page, the element will
 * be tracked again when it becomes visible.
 *
 * @param callbackOrOptions - A callback function to be executed when the
 *  element becomes visible, or an object containing the options for the
 *  Intersection Observer.
 * @param options - An object containing the options for the
 *  Intersection Observer.
 * @returns An array containing the ref to attach to the element and a boolean
 *  indicating whether the element is currently in view.
 *
 * @example
 * Callback way
 * function MyComponent() {
 *   const [elementRef] = useTrackViewedElement(() => {
 *     console.log('Element is in view!');
 *     // Perform actions when the element becomes visible like Gtm.push()
 *     Gtm.push({ event: 'elementInView' });
 *   });
 *
 *   return (
 *     <div ref={elementRef}>
 *       'This element will be tracked for visibility'
 *     </div>
 *   );
 * }
 *
 * @example
 * Boolean way
 * function MyComponent() {
 *   const [elementRef, isElementInView] = useTrackViewedElement();
 *
 *   return (
 *     <div ref={elementRef}>
 *       {isElementInView ? 'Element is in view!' : 'Element is not in view!'}
 *     </div>
 *   );
 * }
 */
export function useTrackViewedElement(
  callback: () => void,
  options?: Options,
): [NodeRef, boolean];
export function useTrackViewedElement(options?: Options): [NodeRef, boolean];
export function useTrackViewedElement(
  callbackOrOptions?: (() => void) | Options,
  options?: Options,
): [NodeRef] | [NodeRef, boolean] {
  // Extract options from callbackOrOptions or options
  const {
    delay,
    threshold,
    triggerOnce,
    skip,
    trackVisibility,
    resetViewedWithSearchParams = false,
    ...otherOptions
  } = (typeof callbackOrOptions === 'function' ? options : callbackOrOptions) ||
  {};
  // Get callback from callbackOrOptions
  const callback =
    typeof callbackOrOptions === 'function' ? callbackOrOptions : undefined;

  const [isViewed, setIsViewed] = useState(false);
  const [ref, inView] = useInView({
    delay: delay !== undefined ? delay : DISPLAY,
    threshold: threshold !== undefined ? threshold : THRESHOLD_MINIMUM_DISPLAY,
    trackVisibility: true,
    triggerOnce: triggerOnce || false,
    skip: skip || false,
    ...otherOptions,
  });

  usePageChange(
    () => {
      setIsViewed(false);
    },
    [setIsViewed],
    { listenSearchParams: resetViewedWithSearchParams },
  );

  useEffect(() => {
    if (inView && !isViewed) {
      setIsViewed(true);

      callback?.();
    }
  }, [callback, inView, isViewed]);

  return [ref, isViewed];
}
