import type { FunctionComponent } from 'react';

import { useUserTiming } from '@/core/observability/utils/useUserTiming';
import { Carousel } from '@/core/tamagoshi/Carousel/Carousel';
import { TipsheetCardVariant } from '@/digitalAdvice/TipsheetCard/constants';
import { TipsheetCard } from '@/digitalAdvice/TipsheetCard/TipsheetCard.lazy';
import { SectionTitle } from '@/retention/Homepage/b2c/components/SectionTitle/SectionTitle';
import { useGuides } from '@/retention/Homepage/b2c/hooks/useGuides';
import { getScrollByFromWidth } from '@/retention/Homepage/b2c/utils/getScrollByFromWidth';

import {
  ariaLabelNext,
  ariaLabelPrevious,
  title as blockTitle,
} from './translations';

import styles from './Advice.module.scss';

export const Advice: FunctionComponent = () => {
  useUserTiming('homepageB2C/guides');
  const guides = useGuides();

  if (guides.length === 0) {
    return null;
  }

  const scrollBy = getScrollByFromWidth({ itemWidth: 304 });

  return (
    <div className={styles.root}>
      <section className={styles.section}>
        <Carousel
          ariaLabelLeft={ariaLabelPrevious}
          ariaLabelRight={ariaLabelNext}
          dataTestId="tipsheet"
          title={<SectionTitle title={blockTitle} />}
          titleClassName={styles.carouselTitle}
          className={styles.carousel}
          controlsClassName={styles.carouselControls}
          sliderClassName={styles.carouselSlider}
          sliderDirectionOptions={{ scrollBy }}
        >
          {guides.map((guide, index) => (
            <TipsheetCard
              className={styles.carouselCard}
              imageAlt={guide.image.altText}
              imageSrc={guide.image.url}
              key={`carousel-guidecard-${guide.url}`}
              positionIndex={index}
              title={guide.title}
              url={guide.url}
              variant={TipsheetCardVariant.GREEN}
            />
          ))}
        </Carousel>
      </section>
    </div>
  );
};

Advice.displayName = 'Advice';
