import type { Currency } from '@/domains/core/localization/interfaces/Currency';
import { getCurrency, getLocale } from '@/domains/core/settings/utils';

export interface FormatOptions {
  minimumFractionDigits?: number;
  minimumIntegerDigits?: number;
  minimumSignificantDigits?: number;
  maximumFractionDigits?: number;
  maximumSignificantDigits?: number;
}

export const formatCurrency = (
  value: number | string,
  currency?: Currency,
  options?: FormatOptions,
) => {
  const parsedNumber: number =
    typeof value === 'string' ? parseFloat(value) : value;
  return new Intl.NumberFormat(getLocale(), {
    style: 'currency',
    currency: currency || getCurrency(),
    ...options,
  }).format(parsedNumber);
};
