import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';
import LazyHydrate from 'react-lazy-hydration';

import { generateLoadingByElementId } from '@/core/lazyHydrate/generateLoadingByElementId';
import type { ReassuranceProps } from '@/retention/Homepage/b2c/modules/Reassurance/Reassurance';

const DynamicReassurance = dynamic<ReassuranceProps>(
  () =>
    import('@/retention/Homepage/b2c/modules/Reassurance/Reassurance').then(
      (mod) => mod.Reassurance,
    ),
  {
    ssr: true,
    loading: () => generateLoadingByElementId('reassurance'),
  },
);

export const LazyReassurance: FunctionComponent = () => (
  <LazyHydrate whenVisible id="reassurance">
    <DynamicReassurance />
  </LazyHydrate>
);

LazyReassurance.displayName = 'LazyReassurance';
