import { useEffect, useRef, useState, type RefObject } from 'react';
import { compose } from 'redux';
import { v4 } from 'uuid';

type ObserverCallbackEntry = [string, HTMLElement, () => void];

const OBSERVER_ROOT_MARGIN = '100px';
const DEFAULT_THRESHOLD = 0;
const VISIBLE_DELAY_TO_LOAD_MS = 100;

const observerCallbackEntries = [] as ObserverCallbackEntry[];
const timeoutIDs: Map<string, number | undefined> = new Map();
const observersCache: Map<string, IntersectionObserver> = new Map();

const findCallBackEntry = (
  target: Element,
): ObserverCallbackEntry | undefined =>
  observerCallbackEntries.find(([, elem]) => elem === target);

const cancelCallback = (callbackEntry?: ObserverCallbackEntry) => {
  if (!callbackEntry) {
    return;
  }
  const timeoutId = timeoutIDs.get(callbackEntry[0]);
  if (!timeoutId) {
    return;
  }
  window.clearTimeout(timeoutId);
  timeoutIDs.delete(callbackEntry[0]);
};

const delayCallback = (callbackEntry?: ObserverCallbackEntry) => {
  if (!callbackEntry) {
    return;
  }
  let timeoutId = timeoutIDs.get(callbackEntry[0]);
  if (timeoutId) {
    return;
  }
  timeoutId = window.setTimeout(() => {
    callbackEntry[2]();
    timeoutIDs.delete(callbackEntry[0]);
  }, VISIBLE_DELAY_TO_LOAD_MS);
  timeoutIDs.set(callbackEntry[0], timeoutId);
};

const findAndCancelCallback = compose(cancelCallback, findCallBackEntry);
const findAndDelayCallback = compose(delayCallback, findCallBackEntry);

export function useLazy<T extends HTMLElement>(
  elementRef: RefObject<T>,
  enabled = true,
  options: Omit<IntersectionObserverInit, 'root'> = {
    rootMargin: OBSERVER_ROOT_MARGIN,
    threshold: DEFAULT_THRESHOLD,
  },
): { hasBeenDisplayed: boolean } {
  const [hasBeenDisplayed, setHasBeenDisplayed] = useState(!enabled);
  const lazyTimeoutIDRef = useRef(v4());
  const { rootMargin = OBSERVER_ROOT_MARGIN, threshold = DEFAULT_THRESHOLD } =
    options;

  useEffect(() => {
    const element = elementRef.current;
    const lazyTimeoutID = lazyTimeoutIDRef.current;

    if (element && enabled) {
      timeoutIDs.set(lazyTimeoutID, undefined);
      const observer = getObserver({ rootMargin, threshold });
      const callbackEntry = [
        lazyTimeoutID,
        element,
        () => {
          observer.unobserve(element);
          setHasBeenDisplayed(true);
        },
      ] as ObserverCallbackEntry;

      observer.observe(element);
      observerCallbackEntries.push(callbackEntry);

      return () => {
        findAndCancelCallback(element);
        observer.unobserve(element);
        observerCallbackEntries.splice(
          observerCallbackEntries.indexOf(callbackEntry),
          1,
        );
      };
    }
  }, [elementRef, enabled, rootMargin, threshold]);

  return { hasBeenDisplayed };
}

function getObserver(
  options: Omit<IntersectionObserverInit, 'root'>,
): IntersectionObserver {
  // The cache is required to avoid an IntersectionObserver per element while it's the same callback.
  const observerId = Object.values(options).toString();

  let observer = observersCache.get(observerId);
  if (!observer) {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          findAndDelayCallback(entry.target);
        } else {
          findAndCancelCallback(entry.target);
        }
      });
    }, options);
    observersCache.set(observerId, observer);
  }

  return observer;
}
