import {
  sponsorInfoBannerNoUserSegmentDescription,
  sponsorInfoBannerWithUserSegmentsDescription,
} from '@/domains/productDiscovery/CommercialAnimation/translations';
import {
  advertiserName,
  advertiserPayerName,
  knowMoreLink,
  sponsorInfoTitle,
} from '@/domains/productDiscovery/SponsorInformations/translations';

export const createAdvertiserInformationPopupContent = (
  advertiser: string,
  sponsor: string,
  userSegments: boolean,
  className?: string,
) => `
  <div class=${className}>
    <header>${sponsorInfoTitle || ''}</header>
    <div>
      <p>
        ${
          userSegments
            ? sponsorInfoBannerWithUserSegmentsDescription.format({
                a: (chunks) => `<a href=${knowMoreLink}>${chunks}</a>`,
              })
            : sponsorInfoBannerNoUserSegmentDescription
        }
      </p>
      <p>
        ${advertiserName.format({
          name: advertiser,
          strong: (chunks) => `<strong>${chunks}</strong>`,
        })}
      </p>
      <p>
        ${advertiserPayerName.format({
          name: sponsor,
          strong: (chunks) => `<strong>${chunks}</strong>`,
        })}
      </p>
    </div>
  </div>
`;
