import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useProductHistory } from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/hooks/useProductHistory';
import {
  selectProductHistoryPending,
  selectProductHistoryPlaylistError,
} from '@/domains/productDiscovery/Recommendation/selectors/productHistory';
import { useSuggestedProducts } from '@/domains/retention/Homepage/b2c/hooks/useSuggestedProducts';
import type { ProductModel } from '@/domains/retention/Homepage/b2c/interfaces/state.model';
import {
  FREQUENTLY_PRODUCTS_PLAYLIST_CONFIG,
  RECENTLY_VIEWED_PRODUCTS_MIN_NUMBER,
  RECENTLY_VIEWED_PRODUCTS_PLAYLIST_CONFIG,
  SELECTED_PRODUCTS_PLAYLIST_CONFIG,
  SUGGESTED_PRODUCTS_PLAYLIST_CONFIG,
} from '@/domains/retention/Homepage/b2c/modules/SuggestedAndRecentlyViewedProducts/constants';
import { FREQUENTLY_BOUGHT_TOGETHER_PRODUCTS_MIN_NUMBER } from '@/retention/Homepage/constants';
import { useRecentlyViewedProductsRecommendations } from '@/retention/Homepage/hooks/useRecentlyViewedProductsRecommendations';

import type { ListingProductsProps } from '../modules/ListingProducts/ListingProducts';
import { convertAlgoliaProductModelToHomepageProductModel } from '../services/convertAlgoliaProductModelToHomepageProductModel';

interface HomepageProductListsData {
  productLists: ListingProductsProps[];
  hasSuggestedOrRecommendedProducts: boolean;
}

export const useHomepageProductLists = (): HomepageProductListsData => {
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<
    ProductModel[] | null
  >(null);
  const { playlists: productHistoryPlaylist, hasBeenAlreadyRetrieved } =
    useProductHistory();
  const productHistoryPlaylistError = useSelector(
    selectProductHistoryPlaylistError,
  );
  const productHistoryPending = useSelector(selectProductHistoryPending);
  const suggestedProducts = useSuggestedProducts();

  const recommendationProducts = useRecentlyViewedProductsRecommendations(
    'FrequentlyBoughtTogether',
  );

  useEffect(() => {
    if (
      (hasBeenAlreadyRetrieved &&
        productHistoryPending === false &&
        (productHistoryPlaylist === undefined ||
          productHistoryPlaylist?.[0]?.products.length <
            RECENTLY_VIEWED_PRODUCTS_MIN_NUMBER)) ||
      (hasBeenAlreadyRetrieved && productHistoryPlaylistError)
    ) {
      setRecentlyViewedProducts([]);
    }
  }, [
    hasBeenAlreadyRetrieved,
    productHistoryPlaylist,
    productHistoryPending,
    productHistoryPlaylistError,
  ]);

  useEffect(() => {
    if (productHistoryPlaylist && productHistoryPlaylist[0]?.products) {
      const newProducts = productHistoryPlaylist[0].products.map((product) =>
        convertAlgoliaProductModelToHomepageProductModel(product),
      );

      if (recentlyViewedProducts === null) {
        setRecentlyViewedProducts(newProducts);
      } else {
        const existingProductIds = recentlyViewedProducts.map(
          (product) => product.productId,
        );
        const addedProducts = newProducts.filter(
          (product) => !existingProductIds.includes(product.productId),
        );

        if (addedProducts.length > 0) {
          setRecentlyViewedProducts([
            ...recentlyViewedProducts,
            ...addedProducts,
          ]);
        }
      }
    }
  }, [productHistoryPlaylist, recentlyViewedProducts]);

  let list1: ListingProductsProps = {};
  let list2: ListingProductsProps = {};

  if (recentlyViewedProducts !== null) {
    if (recentlyViewedProducts.length < RECENTLY_VIEWED_PRODUCTS_MIN_NUMBER) {
      list1 = {
        ...SELECTED_PRODUCTS_PLAYLIST_CONFIG,
        ...(suggestedProducts && {
          products: suggestedProducts.filter((_, index) => index % 2 === 0),
        }),
      };

      list2 = {
        ...SUGGESTED_PRODUCTS_PLAYLIST_CONFIG,
        ...(suggestedProducts && {
          products: suggestedProducts.filter((_, index) => index % 2 === 1),
        }),
      };
    } else if (
      recommendationProducts &&
      recommendationProducts.length >=
        FREQUENTLY_BOUGHT_TOGETHER_PRODUCTS_MIN_NUMBER
    ) {
      const processedRecommendations = recommendationProducts.map((product) =>
        convertAlgoliaProductModelToHomepageProductModel(product),
      );
      list1 = {
        ...RECENTLY_VIEWED_PRODUCTS_PLAYLIST_CONFIG,
        products: recentlyViewedProducts,
      };

      list2 = {
        ...FREQUENTLY_PRODUCTS_PLAYLIST_CONFIG,
        ...(processedRecommendations && { products: processedRecommendations }),
      };
    } else {
      list1 = {
        ...RECENTLY_VIEWED_PRODUCTS_PLAYLIST_CONFIG,
        products: recentlyViewedProducts,
      };

      list2 = {
        ...SUGGESTED_PRODUCTS_PLAYLIST_CONFIG,
        ...(suggestedProducts && { products: suggestedProducts.slice(0, 12) }),
      };
    }
  }
  return {
    productLists: [list1, list2],
    hasSuggestedOrRecommendedProducts:
      !!(
        recommendationProducts &&
        recommendationProducts.length >=
          FREQUENTLY_BOUGHT_TOGETHER_PRODUCTS_MIN_NUMBER
      ) || !!(suggestedProducts && suggestedProducts.length > 0),
  };
};
