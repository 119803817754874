import classnames from 'classnames';
import { forwardRef, type ElementType } from 'react';

import type { TextProps } from '../Text/Text';

import textStyles from '../Text/Text.module.scss';
import styles from './TextSkeleton.module.scss';

export type TextSkeletonProps = TextProps<ElementType> & {
  lines?: number;
};

export const TextSkeleton = forwardRef<HTMLElement, TextSkeletonProps>(
  (
    {
      lines = 1,
      tag: Tag = 'div',
      type = 'body2',
      className,
      theme: themeProp,
      ...restProps
    },
    ref,
  ) => {
    const textClassName = classnames(
      className,
      textStyles.root,
      textStyles[type],
    );

    const children: JSX.Element[] = [];
    for (let i = 0; i < lines; i += 1) {
      children.push(
        <div key={`child${i}`} className={styles.line}>
          &nbsp;
        </div>,
      );
    }

    return (
      <Tag {...restProps} ref={ref} className={textClassName}>
        {children}
      </Tag>
    );
  },
);

TextSkeleton.displayName = 'TextSkeleton';
