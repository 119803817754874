import {
  recentlyTitle,
  selectedProductTitle,
  suggestedProductTitle,
} from '@/retention/Homepage/b2c/modules/ListingProducts/translations';
import { frequentlyBoughtTitle } from '@/retention/Homepage/translations';

export const RECENTLY_VIEWED_PRODUCTS_MIN_NUMBER = 4;

export const SELECTED_PRODUCTS_PLAYLIST_CONFIG = {
  dataTestId: 'selected-products',
  translations: {
    title: selectedProductTitle,
  },
  tracking: {
    recommendationEngineName: 'BestSellers',
    blockClicked: 'selected products',
  },
  products: [],
};

export const SUGGESTED_PRODUCTS_PLAYLIST_CONFIG = {
  dataTestId: 'suggested-products',
  translations: {
    title: suggestedProductTitle,
  },
  tracking: {
    recommendationEngineName: 'BestSellers',
    blockClicked: 'suggested products',
  },
  products: [],
};

export const RECENTLY_VIEWED_PRODUCTS_PLAYLIST_CONFIG = {
  dataTestId: 'recently-viewed-products',
  translations: { title: recentlyTitle },
  tracking: {
    recommendationEngineName: 'RecentlyViewed',
    blockClicked: 'recently viewed products',
  },
  products: [],
};

export const FREQUENTLY_PRODUCTS_PLAYLIST_CONFIG = {
  dataTestId: 'frequently-bought-products',
  translations: {
    title: frequentlyBoughtTitle,
  },
  tracking: {
    recommendationEngineName: 'FrequentlyBoughtTogether',
    blockClicked: 'frequently bought together products',
  },
  products: [],
};
