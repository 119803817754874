import dynamic from 'next/dynamic';

import { Skeleton } from '@/core/tamagoshi/Skeleton/Skeleton';
import { getLazyHydrateTools } from '@/domains/core/lazyHydrate/lazyHydrateTool';

import { type TipsheetCardProps } from './TipsheetCard';

const { dynamicOptions, makeLazyHydrated } =
  getLazyHydrateTools<TipsheetCardProps>({
    id: 'digitalAdvice.TipsheetCard',
    on: 'visible',
    placeholder: <Skeleton height="100%" />,
  });

const DynamicTipsheetCard = dynamic<TipsheetCardProps>(
  async () =>
    (await import('@/digitalAdvice/TipsheetCard/TipsheetCard')).TipsheetCard,
  { ...dynamicOptions },
);

export const TipsheetCard = makeLazyHydrated(DynamicTipsheetCard);
