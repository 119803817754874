import {
  subtitle,
  title,
} from '@/domains/retention/Homepage/b2c/modules/SuggestedAndRecentlyViewedProducts/translations';
import { HomepageTertiaryCampaigns } from '@/domains/retention/Homepage/components/HomepageCampaigns/HomepageTertiaryCampaigns/HomepageTertiaryCampaigns';
import { useHomepageTertiary } from '@/domains/retention/Homepage/hooks/useHomepageTertiary';

import styles from './HomepageTertiaryCampaignsSection.module.scss';

export const HomepageTertiaryCampaignsSection = () => {
  const tertiaryCampaigns = useHomepageTertiary();

  return (
    <>
      {tertiaryCampaigns && tertiaryCampaigns.length > 1 && (
        <div className={styles.tertiaryCampaignsBlock}>
          <span className={styles.campaignTitle}>{title}</span>
          <span className={styles.campaignSubtitle}>{subtitle}</span>
          <HomepageTertiaryCampaigns className={styles.tertiaryCampaigns} />
        </div>
      )}
    </>
  );
};
