import dynamic from 'next/dynamic';

import { getLazyHydrateTools } from '@/core/lazyHydrate/lazyHydrateTool';
import { Skeleton } from '@/core/tamagoshi/Skeleton/Skeleton';
import { type PromotedProductsProps } from '@/retention/Homepage/b2c/modules/PromotedProducts/PromotedProducts';

const { dynamicOptions, makeLazyHydrated } =
  getLazyHydrateTools<PromotedProductsProps>({
    id: 'promoted-products',
    on: 'visible',
    placeholder: <Skeleton width="100%" height="440px" />,
  });

const DynamicPromotedProducts = dynamic(
  async () => (await import('./PromotedProducts')).PromotedProducts,
  { ssr: true, ...dynamicOptions },
);

export const PromotedProducts = makeLazyHydrated(DynamicPromotedProducts);
