import { useEffect, useState, type FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';

import { LazySecondaryPromotions } from '@/domains/retention/Homepage/b2c/modules/SecondaryPromotions/LazySecondaryPromotions';

interface SecondaryPromotionsContainerProps {
  className?: string;
}

export const SecondaryPromotionsContainer: FunctionComponent<
  SecondaryPromotionsContainerProps
> = ({ className }) => {
  const [secondaryRef, secondaryInView] = useInView({
    rootMargin: '300px 0px',
  });
  const [showSecondary, setShowSecondary] = useState(secondaryInView);

  useEffect(() => {
    if (secondaryInView && !showSecondary) {
      setShowSecondary(true);
    }
  }, [secondaryInView, showSecondary]);

  return (
    <div
      className={className}
      ref={secondaryRef}
      data-testid="parent-secondary-promotions"
    >
      {showSecondary && <LazySecondaryPromotions />}
    </div>
  );
};

SecondaryPromotionsContainer.displayName = 'SecondaryPromotionsContainer';
