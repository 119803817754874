import { useEffect } from 'react';
import type { Reducer } from 'redux';

import logger from '../../observability/logger';
import { IS_LOCAL } from '../../platform/constants';
import { useStoreManager } from './useStoreManager';

export function useDynamicReducer(
  key: string,
  reducer: Reducer<any, any>,
  options: { clean?: boolean } = {},
): void {
  const { clean = false } = options;
  const { storeManager } = useStoreManager();

  useEffect(() => {
    const reducerStatus = storeManager?.getReducerStatus(key);

    if (IS_LOCAL) {
      const {
        isApplicationStateWarningsEnabled,
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      } = require('@/devTools/DevTools/components/Performance/applicationStateWarnings');

      if (
        isApplicationStateWarningsEnabled() &&
        reducerStatus === 'registered'
      ) {
        logger.error(
          Error(
            `Reducer with key "${key}" has already been registered. This useDynamicReducer() call could be avoided.`,
          ),
        );
      }
    }

    if (reducerStatus === 'not-registered' || reducerStatus === 'replaceable') {
      storeManager?.injectReducer(key, reducer);
    }

    if (clean) {
      return () => {
        storeManager?.removeReducer(key);
      };
    }
  }, [key, clean, reducer, storeManager]);
}
