export const getScrollByFromWidth = ({
  itemWidth,
  maxPlaylistWidth = 1376,
  minScrollBy = 1,
  maxScrollBy = 5,
}: {
  itemWidth: number;
  maxPlaylistWidth?: number;
  minScrollBy?: number;
  maxScrollBy?: number;
}) =>
  typeof window !== 'undefined' && window.innerWidth
    ? () =>
        Math.max(
          minScrollBy,
          Math.min(
            maxScrollBy,
            Math.floor(
              Math.min(window.innerWidth, maxPlaylistWidth) / itemWidth,
            ),
          ),
        )
    : minScrollBy;
