import { translate, translateFormatter, type RichTextFormatter } from '@/domains/core/localization/translate';
export const {
  sponsorBrandInfoTitle
} = translate({
  "sponsorBrandInfoTitle": "Information"
});
export const sponsorBrandInfoDescription = translateFormatter<{
  brand: string;
}>("Contenu sponsoris\xE9 par la marque {brand}", undefined, undefined, "fr");
export const {
  sponsorInfoTitle
} = translate({
  "sponsorInfoTitle": "Information \xE0 propos d'un lien sponsoris\xE9"
});
export const sponsorInfoModalDescription = translateFormatter("Produits sponsoris\xE9s dans le cadre d'un service optionnel payant souscrit par le Marchand. <a>En savoir plus</a>. Vous voyez cette annonce en fonction des termes de votre recherche ou des produits vus sur cette page ou des produits consult\xE9s sur cette page.", undefined, undefined, "fr");
export const sponsorInfoDescription = translateFormatter("Produit sponsoris\xE9 dans le cadre d'un service optionnel payant souscrit par le Marchand. <a>En savoir plus</a>. Vous voyez cette annonce en fonction de votre recherche ou des produits consult\xE9s sur cette page.", undefined, undefined, "fr");
export const {
  knowMoreLink
} = translate({
  "knowMoreLink": "https://cdn.manomano.com/legal/consumer-information/fr.pdf"
});
export const advertiserName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>Nom de l'annonceur :</strong> {name}", undefined, undefined, "fr");
export const advertiserPayerName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>Qui paye pour cette publicit\xE9 :</strong> {name}", undefined, undefined, "fr");