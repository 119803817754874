import Head from 'next/head';
import type { FunctionComponent } from 'react';

import { metaDescription } from '@/core/page/components/MetaTags';
import { getCurrentBaseUrl } from '@/core/platform/getCurrentBaseUrl';
import { routes } from '@/core/routing/routes/routes.default';
import { createURL } from '@/core/routing/URLParser';
import { getLocale } from '@/core/settings/utils';
import logo1080Src from '@/retention/Brand/assets/logo1080.png';
import { pageName } from '@/retention/Homepage/b2c/translations';

const TITLE = 'ManoMano';
const DESCRIPTION = `ManoMano : ${metaDescription}`;

export const HomepageMeta: FunctionComponent = () => {
  const baseUrl = getCurrentBaseUrl();
  const previewSrc = `${baseUrl}${logo1080Src}`;

  const homepageSchema = {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    name: TITLE,
    url: baseUrl,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${baseUrl}${createURL(routes.search, { query: '{search_term}' })}`,
      'query-input': 'required name=search_term',
    },
  };

  return (
    <Head>
      <title key="meta_title">{pageName}</title>
      <meta name="description" content={DESCRIPTION} key="meta_description" />

      <meta property="og:type" content="WebSite" key="og-type" />
      <meta property="og:title" content={TITLE} key="og-title" />
      <meta
        property="og:description"
        content={DESCRIPTION}
        key="og-description"
      />
      <meta property="og:image" content={previewSrc} key="og-image" />
      <meta property="og:locale" content={getLocale()} key="og-locale" />
      <meta property="og:site_name" content="ManoMano" key="og-site-name" />

      <meta name="twitter:url" content={baseUrl} key="twitter-url" />
      <meta name="twitter:title" content={TITLE} key="twitter-title" />
      <meta
        name="twitter:description"
        content={DESCRIPTION}
        key="twitter-description"
      />
      <meta name="twitter:image" content={previewSrc} key="twitter-image" />
      <meta name="twitter:card" content={pageName} key="twitter:card" />

      <link rel="canonical" href={baseUrl} key="canonical" />

      <link
        rel="alternate"
        hrefLang="de"
        href="https://www.manomano.de/"
        key="alternate-de"
      />
      <link
        rel="alternate"
        hrefLang="es"
        href="https://www.manomano.es/"
        key="alternate-es"
      />
      <link
        rel="alternate"
        hrefLang="fr"
        href="https://www.manomano.fr/"
        key="alternate-fr"
      />
      <link
        rel="alternate"
        hrefLang="en-gb"
        href="https://www.manomano.co.uk/"
        key="alternate-en"
      />
      <link
        rel="alternate"
        hrefLang="it"
        href="https://www.manomano.it/"
        key="alternate-it"
      />

      <script
        type="application/ld+json"
        key="microdata"
        data-testid="structuredDataSEOProductElm"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(homepageSchema) }}
      />
    </Head>
  );
};
