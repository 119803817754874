import { useSelector } from 'react-redux';

import type { ProductModel } from '@/retention/Homepage/b2c/interfaces/state.model';

import type { SpartacuxRetentionState } from '../../../retention.model';

export const suggestedProductsSelector = (
  state: SpartacuxRetentionState,
): ProductModel[] | undefined =>
  state.retention?.homepage?.data?.suggestedProducts;

export const useSuggestedProducts = () =>
  useSelector(suggestedProductsSelector);
