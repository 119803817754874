import categoriesJson from '@/retention/Homepage/b2c/modules/Seo/utils/data/categories.default.json';

import type {
  SeoItemFromJson,
  SeoModelFromJson,
} from '../../../interfaces/data.model';
import type { SeoItem, SeoModel } from '../../../interfaces/state.model';

const processSeoItem = (item: SeoItemFromJson): SeoItem => ({
  title: item.title,
  url: item.url,
});

const processSeo = (seoCategory: SeoModelFromJson): SeoModel => ({
  familyId: seoCategory.family_id,
  categoryName: seoCategory.category_name,
  categoryPath: seoCategory.category_path,
  items: seoCategory.items.map(processSeoItem),
});

export const getSeoData = (): SeoModel[] => categoriesJson.data.map(processSeo);
