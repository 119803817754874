import dynamic from 'next/dynamic';

import { getLazyHydrateTools } from '@/core/lazyHydrate/lazyHydrateTool';
import { Skeleton } from '@/core/tamagoshi/Skeleton/Skeleton';
import { type SuggestedCategoriesProps } from '@/retention/Homepage/b2c/modules/SuggestedCategories/SuggestedCategories';

const { dynamicOptions, makeLazyHydrated } =
  getLazyHydrateTools<SuggestedCategoriesProps>({
    id: 'suggested-categories',
    on: 'visible',
    placeholder: <Skeleton width="100%" height="384px" />,
  });

const DynamicSuggestedCategories = dynamic(
  async () => (await import('./SuggestedCategories')).SuggestedCategories,
  { ssr: true, ...dynamicOptions },
);

export const SuggestedCategories = makeLazyHydrated(DynamicSuggestedCategories);
