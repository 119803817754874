import type { DynamicOptions } from 'next/dynamic';
import {
  useState,
  type ComponentType,
  type DetailedHTMLProps,
  type HTMLAttributes,
  type ReactElement,
} from 'react';
import LazyHydrate, { type LazyProps } from 'react-lazy-hydration';

import { generateLoadingByHydrateId } from '@/core/lazyHydrate/generateLoadingByElementId';

function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const EMPTY_STYLE = {}; // Overrides the display: contents attribute

interface HydrateOptions
  extends Omit<LazyProps, 'children'>,
    Omit<React.HTMLProps<HTMLElement>, 'dangerouslySetInnerHTML'> {}

interface LazyHydrateToolReturn<P> {
  dynamicOptions: DynamicOptions<P>;
  makeLazyHydrated(
    Component: ComponentType<P>,
  ): ComponentType<P & JSX.IntrinsicAttributes>;
}

interface GetLazyHydrateToolsParams {
  /**
   * The id is used to query the SSR DOM for loading and to generate displayName
   */
  id: string;
  /**
   * To add `data-testid`
   */
  'data-testid'?: string;
  /**
   * Use it to pass props to the HTML div added as a placeholder until hydration
   */
  loadingElementArgs?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  /**
   * When the hydration has to do his job
   */
  on: 'visible' | 'idle' | 'never';
  /**
   * The placeholder will be used on SPA navigation
   */
  placeholder: ReactElement;
}

export function getLazyHydrateTools<PropTypes>({
  id,
  'data-testid': dataTestId = id,
  loadingElementArgs = {},
  on,
  placeholder,
}: GetLazyHydrateToolsParams): LazyHydrateToolReturn<PropTypes> {
  return {
    dynamicOptions: {
      loading: () =>
        generateLoadingByHydrateId(id, placeholder, loadingElementArgs),
    },
    makeLazyHydrated(Component) {
      const hydrateProps: HydrateOptions = {};

      switch (on) {
        case 'visible':
          hydrateProps.whenVisible = true;
          break;
        case 'idle':
          hydrateProps.whenIdle = true;
          break;
        case 'never':
          hydrateProps.ssrOnly = true;
          break;

        default:
          break;
      }

      const NamedComponent = Object.assign(Component, {
        displayName: `LazyHydrate(${Component.displayName || capitalize(id)})`,
      });

      return ({
        className,
        ...props
      }: PropTypes & JSX.IntrinsicAttributes & { className?: string }) => {
        const [hydrated, setHydrated] = useState(false);
        return (
          <LazyHydrate
            id={id}
            data-testid={dataTestId}
            data-hydrate-id={!hydrated ? id : undefined}
            didHydrate={() => setHydrated(true)}
            style={className && EMPTY_STYLE} // by default, the LazyHydrate wrapper has display content style, if we want to add class, for position for example, we must erase the display content (https://github.com/hadeeb/react-lazy-hydration/blob/master/src/index.tsx#L172)
            className={className}
            {...hydrateProps}
          >
            <NamedComponent
              {...(props as PropTypes & JSX.IntrinsicAttributes)}
            />
          </LazyHydrate>
        );
      };
    },
  };
}
