import type { FunctionComponent } from 'react';

import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type {
  BrandModel,
  SeoItem,
  SeoModel,
} from '@/domains/retention/Homepage/b2c/interfaces/state.model';
import { GTM_CLICK_ON_SEO_BLOCK } from '@/domains/retention/Homepage/b2c/modules/Tracking/events/clickOnSEOBlock';
import { TrackingProvider } from '@/domains/retention/Homepage/b2c/modules/Tracking/TrackingContext';
import { GTM_CLICK_BRAND } from '@/retention/Homepage/tracking/events/clickHomepageBrand';

export const TrackingManager: FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const onClickBrand = (brand: BrandModel) =>
    Gtm.push(GTM_CLICK_BRAND(brand.title));
  const onClickSeoBlock = (
    seoFamily: SeoModel,
    familyIndex: number,
    seoItem?: SeoItem,
    index?: number,
  ) => Gtm.push(GTM_CLICK_ON_SEO_BLOCK(seoFamily, familyIndex, seoItem, index));

  return (
    <TrackingProvider
      value={{
        onClickBrand,
        onClickSeoBlock,
      }}
    >
      {children}
    </TrackingProvider>
  );
};

TrackingManager.displayName = 'TrackingManager';
