import type { FunctionComponent } from 'react';

import { Container } from '@/domains/retention/Homepage/b2c/modules/Container/Container';
import { PageStatusManager } from '@/domains/retention/Homepage/b2c/modules/PageStatusManager/PageStatusManager';
import { TrackingManager } from '@/domains/retention/Homepage/b2c/modules/Tracking/TrackingManager';

const HomepageB2C: FunctionComponent = () => (
  <PageStatusManager>
    <TrackingManager>
      <Container />
    </TrackingManager>
  </PageStatusManager>
);

export default HomepageB2C;
HomepageB2C.displayName = 'HomepageB2C';
