import {
  useCallback,
  type FunctionComponent,
  type KeyboardEvent,
  type MouseEvent,
} from 'react';

import {
  advertiserName,
  advertiserPayerName,
  sponsorInfoDescription,
  sponsorInfoTitle,
} from '../../SponsorInformations/translations';
import { link, SponsoredDescription } from './SponsoredDescription';

import styles from './SponsoredDetailedDescription.module.scss';

export interface SponsoredData {
  adPayer?: 'BRAND' | 'SELLER';
  sellerName: string;
  brandName: string;
}

interface SponsoredDetailedDescriptionProps {
  sponsoredData: SponsoredData;
}

export const SponsoredDetailedDescription: FunctionComponent<
  SponsoredDetailedDescriptionProps
> = ({ sponsoredData }) => {
  const redirectLink = useCallback(
    (event: KeyboardEvent<HTMLSpanElement> | MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (link) {
        window.location.href = link;
      }
      return null;
    },
    [],
  );

  if (sponsoredData.adPayer) {
    return (
      <>
        <p className={styles.title}>{sponsorInfoTitle}</p>
        <p className={styles.description}>
          {sponsorInfoDescription.format({
            a: (chunks: string) => (
              <span
                key={chunks}
                role="link"
                tabIndex={0}
                onClick={redirectLink}
                onKeyPress={redirectLink}
                className={styles.link}
              >
                {chunks}
              </span>
            ),
          })}
        </p>
        <p className={styles.name} data-testid="advertiser">
          {advertiserName.format({
            strong: (chunks) => <strong key="strong">{chunks}</strong>,
            name: sponsoredData.sellerName,
          })}
        </p>
        <p className={styles.name} data-testid="sponsor">
          {advertiserPayerName.format({
            name:
              sponsoredData.adPayer === 'SELLER'
                ? sponsoredData.sellerName
                : sponsoredData.brandName,
            strong: (chunks) => <strong key="strong">{chunks}</strong>,
          })}
        </p>
      </>
    );
  }

  return <SponsoredDescription />;
};

SponsoredDetailedDescription.displayName = 'SponsoredDetailedDescription';
