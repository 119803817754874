import type { FunctionComponent } from 'react';

import {
  ProductCard,
  type ProductCardProps,
} from '@/core/tamagoshi/ProductCard/ProductCard';
import { Skeleton } from '@/core/tamagoshi/Skeleton/Skeleton';
import { TextSkeleton } from '@/core/tamagoshi/TextSkeleton/TextSkeleton';

export type ProductCardSkeletonProps = Partial<
  Pick<
    ProductCardProps,
    'vertical' | 'light' | 'addToCart' | 'translations' | 'style' | 'className'
  >
>;

const imageSize = ({ vertical = false, light = false, narrow = false }) => {
  if (vertical && light) {
    return '158px';
  }

  if (vertical && narrow) {
    return '86px';
  }

  return '142px';
};

const priceStyle = ({ vertical = false, light = false }) => {
  if (vertical && light) {
    return { marginTop: '0', marginBottom: '0' };
  }

  return { marginTop: '36px', marginBottom: '20px' };
};

const addToCartStyle = () => ({ marginTop: '12px' });
const TitleLineHeight = 55 / 3;

export const ProductCardSkeleton: FunctionComponent<
  ProductCardSkeletonProps
> = (props) => {
  const {
    vertical = false,
    light = false,
    addToCart,
    translations,
    ...otherProps
  } = props;
  const lines = light ? 2 : 3;

  return (
    <ProductCard
      title={
        <TextSkeleton
          lines={lines}
          style={{
            lineHeight: `${TitleLineHeight}px`,
            height: `${lines * TitleLineHeight}`,
            width: 'auto',
          }}
        />
      }
      price={0}
      currency="EURO"
      imageComponent={
        <Skeleton width={imageSize(props)} height={imageSize(props)} />
      }
      priceComponent={
        <div style={priceStyle(props)}>
          <Skeleton width="65px" height="22px" />
        </div>
      }
      imageSrc=""
      vertical={vertical}
      light={light}
      addToCart={addToCart}
      translations={translations}
      addToCartSkeleton={
        <div style={addToCartStyle()}>
          <Skeleton width="174px" height="48px" />
        </div>
      }
      {...otherProps}
    />
  );
};

ProductCardSkeleton.displayName = 'ProductCardSkeleton';
