import Head from 'next/head';
import type { FC } from 'react';

import { routes } from '@/domains/core/routing/routes/routes.default';

export const MetaTagsMarket: FC = () => {
  /*TODO: this was a call to 'useFeatureFlags' replaced with the value of the flag/abtest. Consider removing this call when you modify the file.*/
  const [featureFlagSmartAppBanner] = [false];
  return (
    <Head>
      {featureFlagSmartAppBanner && (
        <link
          rel="manifest"
          href={(routes as Record<string, string>).manifest}
        />
      )}
    </Head>
  );
};
