import type { CommercialOperationInfo } from '@/domains/productDiscovery/CommercialAnimation/CommercialAnimation.model';
import type { Product as SuggestionsProduct } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';
import type { Product } from '@/productDiscovery/algolia/interfaces/product';
import { PromoTags } from '@/productDiscovery/CommercialAnimation/interfaces/tags';

export const findPromoForProduct = (
  productCommercialOperationIds: number[],
  commercialOperations: CommercialOperationInfo[],
): CommercialOperationInfo | undefined =>
  commercialOperations
    .filter(({ id }) => productCommercialOperationIds.includes(id))
    .sort((a, b) => {
      if (!a.endAt) {
        return 1;
      }
      if (!b.endAt) {
        return -1;
      }
      return a.endAt - b.endAt;
    })
    .pop();

export const productHasDiscount = (
  mainPrice: number,
  retailPrice: number | null,
  discount?: string | null,
) => {
  return (!!retailPrice && retailPrice > mainPrice) || !!discount;
};

export const getTags = (
  mainPrice: number,
  retailPrice: number | null,
  promoTags?: string[],
  discount?: string | null,
) => {
  const hasDiscount = productHasDiscount(mainPrice, retailPrice, discount);

  if (
    promoTags &&
    promoTags.length > 0 &&
    (hasDiscount || promoTags.includes('loweredprice'))
  ) {
    return promoTags as PromoTags[];
  }

  return [] as PromoTags[];
};

export const getPromoTags = (product: Product | SuggestionsProduct) => {
  return getTags(
    product.prices.main,
    product.prices.retail,
    product.commercialAnimation?.tags,
    product.prices.discount,
  );
};
