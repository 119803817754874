import Head from 'next/head';
import {
  Children,
  cloneElement,
  useRef,
  type FunctionComponent,
  type ReactElement,
} from 'react';

import { useLazy } from '@/domains/core/page/hooks/useLazy';

export type ImageProps = JSX.IntrinsicElements['img'] & {
  /**
   * Alternative text must be provided for accessibility purpose.
   */
  alt: string;
  /*
   * An image cannot have children so picture tag will be used.
   */
  children?: ReactElement | ReactElement[];
  /**
   * Indicates whether the resource has to be loaded in priority or not.
   * Only supported in latest Chromium
   * @see https://web.dev/priority-hints/
   */
  fetchpriority?: 'auto' | 'high' | 'low';
  /**
   * Height should always be provided. If you cannot, it must be
   * explicitly set to undefined.
   *
   * @see https://web.dev/optimize-cls/#images-without-dimensions
   */
  height: number | string | undefined;
  /**
   * Images are lazy loaded by default.
   *
   * You must use 'eager' whether your image will be displayed above the fold,
   * it will make it be loaded immediately.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-loading
   */
  loading?: 'eager' | 'lazy';
  /**
   * Doesn't display any placeholder for lazy loaded images, but beware of CLS problems.
   */
  noPlaceholder?: boolean;
  /**
   * In case of the use of picture tag, styles can be applied on it.
   */
  pictureClassName?: string;
  /**
   * Opt-in optimizations (same attribute than next/image): webp
   */
  unoptimized?: boolean;
  /**
   * Width should always be provided. If you cannot, it must be
   * explicitly set to undefined.
   *
   * @see https://web.dev/optimize-cls/#images-without-dimensions
   */
  width: number | string | undefined;
};

const getPlaceholderSrc = (width: number | string, height: number | string) =>
  `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}'%3E%3Cdefs%3E%3ClinearGradient id='g1'%3E%3Cstop stop-color='%23F5F6F7'/%3E%3Cstop offset='.983' stop-color='%23ECEDF0'/%3E%3Cstop offset='1' stop-color='%23ECEDF0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='url(%23g1)'/%3E%3C/svg%3E`;

export const convertSrcToWebp = (src: string) => {
  const hasQuery = src.includes('?');
  if (hasQuery) {
    return src.replace('?', `?format=webp${hasQuery ? '&' : ''}`);
  }
  const beforewhitespace = /\S+/;
  return src.replace(beforewhitespace, (match) => `${match}?format=webp`);
};
export const convertSrcSetToWebp = (srcSet: string) =>
  srcSet
    .split(',')
    .map((src) => convertSrcToWebp(src))
    .join(',');

export const Image: FunctionComponent<ImageProps> = ({
  alt,
  children,
  loading = 'lazy',
  noPlaceholder = false,
  pictureClassName,
  sizes,
  src,
  srcSet,
  unoptimized = true,
  ...forwardedProps
}) => {
  // Until all images are served by the media server, we cannot optimize by default so unoptimized defaults to true.
  const needOptimizations = !unoptimized;
  const isServerSide = typeof window === 'undefined';
  const isLazy = loading === 'lazy' && !!(src || srcSet);
  let usePreload = !isLazy;
  const imageElementRef = useRef<HTMLImageElement>(null);
  const { hasBeenDisplayed } = useLazy(imageElementRef, isLazy, {
    rootMargin: '200px',
  });
  const { width, height } = forwardedProps;

  let calculatedSrc = src;
  if (!hasBeenDisplayed || !src) {
    calculatedSrc = noPlaceholder
      ? undefined
      : getPlaceholderSrc(width || 300, height || 300);
  }

  const img = (
    // This component is the preferred way to display images.
    // eslint-disable-next-line react/forbid-elements
    <img
      alt={alt}
      ref={imageElementRef}
      sizes={sizes}
      src={calculatedSrc}
      srcSet={hasBeenDisplayed ? srcSet : undefined}
      {...forwardedProps}
    />
  );
  const webpSrcSet =
    needOptimizations && srcSet ? convertSrcSetToWebp(srcSet) : undefined;
  const webpSrc = needOptimizations && src ? convertSrcToWebp(src) : undefined;
  // Picture & sources
  let sources;
  let mainSourceSizes;
  let mainSourceSrcSet;

  if (children) {
    sources = Children.map(children, (source: ReactElement, idx) => {
      if (source.type !== 'source') return null;

      if (idx === 0) {
        mainSourceSizes = source.props.sizes;
        mainSourceSrcSet = source.props.srcSet;
      }

      if (source.props.media) {
        usePreload = false;
      }
      return (
        <>
          {needOptimizations &&
            cloneElement(source, {
              sizes,
              srcSet: hasBeenDisplayed
                ? convertSrcSetToWebp(source.props.srcSet)
                : undefined,
              type: 'image/webp',
            })}
          {cloneElement(source, {
            sizes,
            srcSet: hasBeenDisplayed ? source.props.srcSet : undefined,
          })}
        </>
      );
    });
  }

  return (
    <>
      {isServerSide && usePreload && (src || srcSet || mainSourceSrcSet) && (
        // Eager loading must only be used for images above the fold so we
        // preload them.
        <Head>
          <link
            rel="preload"
            as="image"
            key={`preload-${src}`}
            href={webpSrc || src}
            fetchpriority="high"
            imageSizes={sizes || mainSourceSizes}
            imageSrcSet={
              needOptimizations
                ? webpSrcSet ||
                  (mainSourceSrcSet && convertSrcSetToWebp(mainSourceSrcSet))
                : srcSet || mainSourceSrcSet
            }
          />
        </Head>
      )}
      {children || needOptimizations ? (
        <picture className={pictureClassName}>
          {(webpSrcSet || webpSrc) && hasBeenDisplayed && (
            <source
              srcSet={webpSrcSet || webpSrc}
              sizes={sizes}
              type="image/webp"
            />
          )}
          {sources}
          {img}
        </picture>
      ) : (
        img
      )}
    </>
  );
};
