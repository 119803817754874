import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook to execute a function when the page changes. It's useful when you
 * navigate to the same page but with different params. When you navigate to the
 * same page but with different params, your component won't be unmounted and
 * mounted again.
 *
 * If you want to execute the function when the search params change, you can
 * pass the option listenSearchParams as true.
 * @param fn - Function to execute when the page changes
 * @param dependencies - Dependencies of the function which is in useCallback
 * @param {Object} options - Options of the hook
 * @param {boolean} [options.listenSearchParams = false] - Boolean to indicate
 * if you want to listen the search params
 *  @default false
 *
 * @example
 * const MyComponent = () => {
 *   const handlePageChange = useCallback(() => {
 *     console.log('Page changed!');
 *   }, []);
 *
 *   usePageChange(handlePageChange, [], { listenSearchParams: true });
 *
 *   return <div>My Component</div>;
 * }
 */
export const usePageChange = (
  fn: () => void,
  dependencies: any[],
  options: { listenSearchParams: boolean } = { listenSearchParams: false },
) => {
  const { asPath: newUrl } = useRouter();

  const urlRef = useRef(newUrl);

  const handlePageChange = useCallback(fn, [dependencies, fn]);

  useEffect(() => {
    const [oldPathname] = urlRef.current?.split('?') ?? [];
    const [newPathname] = newUrl?.split('?') ?? [];

    if (
      (!!oldPathname && oldPathname !== newPathname) ||
      (options.listenSearchParams && urlRef.current !== newUrl)
    ) {
      urlRef.current = newUrl;
      handlePageChange();
    }
  }, [fn, newUrl, handlePageChange, options.listenSearchParams]);
};
